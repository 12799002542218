<template>
    <client-page>
        <guide-visual v-bind="visual" />

        <div class="client-content-wrap">
            <scrolldown-tail />

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">
                            생일·결혼·출산 등 소중한 날에 후원하여, <br class="d-none d-lg-block" />
                            더욱 특별하고 의미 있는 하루로 만들어 보세요.
                        </h2>
                    </div>

                    <v-row class="row--xxs">
                        <v-col v-for="(day, index) in specialDays" :key="index" cols="6" md="4">
                            <v-card flat color="#FAFAFA" class="day-card">
                                <div>
                                    <v-card-title class="tit tit--sm justify-center primary--text">
                                        {{ day.name }}
                                    </v-card-title>
                                    <v-card-text class="text-center page-text page-text--lg break-keep-all">
                                        <p v-html="day.text" />
                                    </v-card-text>
                                </div>
                                <div class="v-card__image">
                                    <v-img :src="day.image" />
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">지정사업 후원</h2>
                        <p class="section-subtitle">지정사업 후원은 귀중한 기부금을 투명하게 전달하고, 후원자가 원하는 분야에 직접적인 도움을 줄 수 있다는 점이 특징입니다.</p>
                    </div>
                </v-container>
                <v-card img="/images/guide/temporary/business-support.jpg" tile flat class="py-60 py-lg-100 business-support">
                    <v-container>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-img max-width="424" src="/images/guide/temporary/business-support-text.svg" alt="세상을 위한 따뜻한 변화, 그 중심에는 나눔의 가치를 실천하는 마음이 있습니다. 따뜻한 나눔을 일시후원으로 시작해 보세요." class="mx-auto mb-24" />
                                <v-card flat class="pa-20 pa-lg-40 text-center border-radius-16">
                                    <v-sheet color="#F2F9EC" class="pa-18 pa-lg-24">
                                        <v-card color="primary" height="36" outlined rounded="pill" class="font-size-18 px-24 d-inline-flex align-center">
                                            <span class="primary--text">계좌번호 안내</span>
                                        </v-card>
                                        <div class="tit tit--sm mt-16 primary--text">
                                            KB국민은행 사회복지법인 위드캔 복지재단 <br />
                                            017037-04-003957
                                        </div>
                                    </v-sheet>
                                    <p class="page-text page-text--lg mt-16">
                                        기부금영수증 국세청 등록을 원하시는 경우, <br />
                                        계좌 입금 후 후원자서비스센터(02-2057-2296)로 연락 주시면 <br />
                                        유선 안내에 따라 필요한 정보를 제공해 주시기 바랍니다.
                                    </p>
                                    <v-btn href="https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w" target="_blank" color="primary" large rounded="pill" class="mt-20 mt-md-40">
                                        <span class="font-size-18">전체사업 일시후원</span>
                                        <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="border-radius-16">
                        <v-row no-gutters>
                            <v-col cols="12" lg="6">
                                <v-card color="#EFF5E6" flat tile class="information-card">
                                    <v-card-title class="tit tit--md line-height-1 justify-center primary--text font-weight-regular pb-0 text-center">
                                        <h3>해외 지정사업 후원하기</h3>
                                        <p class="page-text page-text--lg grey-1--text mt-18 mt-lg-24 text-center">
                                            해외 식수시설 설치, 학교 건립 및 지원 등의 <br />
                                            다양한 사업을 후원하실 수 있습니다.
                                        </p>
                                    </v-card-title>
                                    <v-card-text class="py-20 py-lg-32">
                                        <v-sheet color="primary" width="100%" height="1" />
                                    </v-card-text>
                                    <v-card-text class="py-0">
                                        <ul>
                                            <li>
                                                <v-row align="center" class="row--xxs">
                                                    <v-col cols="auto">
                                                        <v-card color="primary" width="86" height="30" outlined class="rounded-pill d-flex align-center justify-center">
                                                            <span class="font-size-16 primary--text">비용</span>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col>
                                                        <p class="page-text page-text--lg">
                                                            1,000,000원 이상 <br />
                                                            (개별상담을 통해 소액후원 진행도 가능합니다.)
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </li>
                                            <li>
                                                <v-row align="center" class="row--xxs">
                                                    <v-col cols="auto">
                                                        <v-card color="primary" width="86" height="30" outlined class="rounded-pill d-flex align-center justify-center">
                                                            <span class="font-size-16 primary--text">지원내용</span>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col>
                                                        <p class="page-text page-text--lg">우물설치 / 학교 건축 및 교육 / 소득증대 사업 지원 등</p>
                                                    </v-col>
                                                </v-row>
                                            </li>
                                        </ul>

                                        <div class="mt-20 mt-md-32">
                                            <v-row class="row--xxs">
                                                <v-col cols="6">
                                                    <v-card tile flat height="100%">
                                                        <v-img src="/images/guide/temporary/business-1.jpg" alt="" />
                                                        <v-card-text class="text-center page-text page-text--sm pa-12">해외 식수 설치</v-card-text>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-card tile flat height="100%">
                                                        <v-img src="/images/guide/temporary/business-2.jpg" alt="" />
                                                        <v-card-text class="text-center page-text page-text--sm pa-12">학교 건축, 개보수 및 교육 기자재 지원</v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-center pt-20 pt-lg-40">
                                        <v-btn color="primary" large rounded="pill" class="px-24">
                                            <span class="font-size-18">해외 지정사업 후원문의</span>
                                            <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-card color="#FFFBE5" flat tile class="information-card">
                                    <v-card-title class="tit tit--md line-height-1 justify-center primary--text font-weight-regular pb-0 text-center">
                                        <h3>국내 지정사업 후원하기</h3>
                                        <p class="page-text page-text--lg grey-1--text mt-18 mt-lg-24 text-center">
                                            빈곤가정 및 아이들을 위한 급식, 문화활동 지원 등의 <br />
                                            다양한 사업을 후원하실 수 있습니다.
                                        </p>
                                    </v-card-title>
                                    <v-card-text class="py-20 py-lg-32">
                                        <v-sheet color="primary" width="100%" height="1" />
                                    </v-card-text>
                                    <v-card-text class="py-0">
                                        <ul>
                                            <li>
                                                <v-row align="center" class="row--xxs">
                                                    <v-col cols="auto">
                                                        <v-card color="primary" width="86" height="30" outlined class="rounded-pill d-flex align-center justify-center">
                                                            <span class="font-size-16 primary--text">비용</span>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col>
                                                        <p class="page-text page-text--lg">
                                                            1,000,000원 이상 <br />
                                                            (개별상담을 통해 소액후원 진행도 가능합니다.)
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </li>
                                            <li>
                                                <v-row align="center" class="row--xxs">
                                                    <v-col cols="auto">
                                                        <v-card color="primary" width="86" height="30" outlined class="rounded-pill d-flex align-center justify-center">
                                                            <span class="font-size-16 primary--text">지원내용</span>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col>
                                                        <p class="page-text page-text--lg">
                                                            따뜻한 도시락 제공 / 방학 중 소외된 아동 보호 / <br />
                                                            방과 후 문화체험 지원 / 지역아동 센터 개보수 등
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </li>
                                        </ul>

                                        <div class="mt-20 mt-md-32">
                                            <v-row class="row--xxs">
                                                <v-col cols="6">
                                                    <v-card tile flat height="100%">
                                                        <v-img src="/images/guide/temporary/business-3.jpg" alt="" />
                                                        <v-card-text class="text-center page-text page-text--sm pa-12">국내 빈곤가정 지원 및 급식지원</v-card-text>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-card tile flat height="100%">
                                                        <v-img src="/images/guide/temporary/business-4.jpg" alt="" />
                                                        <v-card-text class="text-center page-text page-text--sm pa-12">학대피해아동 심리치료 지원</v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-center pt-20 pt-lg-40">
                                        <v-btn color="primary" large rounded="pill" class="px-24">
                                            <span class="font-size-18">해외 지정사업 후원문의</span>
                                            <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">지정사업 후원 진행과정</h2>
                        <p class="section-subtitle">
                            개인, 단체, 팬클럽, 기업 등 다양한 후원자가 국내외 사업 중 원하는 사업을 상담을 통해 선정하여 진행할 수 있는 맞춤형 후원 방식입니다. <br class="d-none d-xl-block" />
                            지정사업후원을 하시면, 진행된 사업의 현장을 담은 개별화된 결과보고서를 받아보실 수 있습니다.
                        </p>
                    </div>

                    <v-row class="process-list row--lg">
                        <v-col v-for="(item, index) in process" :key="index" cols="12" lg="3">
                            <v-card color="#FAFAFA" flat class="process-card">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="primary--text text-center font-weight-bold font-size-28">0{{ index + 1 }}</div>
                                        <div class="page-text page-text--lg grey-1--text text-center mt-8">
                                            {{ item.name }}
                                        </div>
                                        <v-img :src="`/images/guide/temporary/progress-${index + 1}.svg`" max-width="60" class="mt-24 mx-auto" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">일시후원금은 어떻게 사용될까요?</h2>
                    </div>

                    <v-row>
                        <v-col v-for="(business, index) in businesses" :key="index" cols="12" md="4">
                            <v-card max-width="384" flat outlined class="business-card">
                                <v-img :src="business.image" />
                                <v-card-title class="tit tit--sm justify-center primary--text">
                                    {{ business.title }}
                                </v-card-title>
                                <v-card-text class="text-center page-text page-text--lg break-keep-all">
                                    {{ business.texts }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </div>
    </client-page>
</template>

<script>
import GuideVisual from "@/components/client/guide/guide-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import ScrolldownTail from "@/components/dumb/scrolldown-tail.vue";

export default {
    components: {
        ClientPage,
        GuideVisual,
        ScrolldownTail,
    },
    data() {
        return {
            visual: {
                image: "/images/guide/temporary/visual.jpg",
                title: "일시후원",
                texts: "일시후원은 특정한 날·행사·사업 등을 지정해 후원함으로써  <br />의미 있는 가치를 더하는 나눔입니다. <br />후원금은 긴급히 도움이 필요한 이웃들에게 전달되어 큰 힘이 됩니다.",
            },

            specialDays: [
                {
                    name: "생일",
                    text: "가장 특별하고 많은 사람에게 축하 받고 싶은 날, <br class='d-none d-xl-block'>주변의 소중한 사람들과 함께 기념해요.",
                    image: "/images/guide/temporary/day-birthday.jpg",
                },
                {
                    name: "결혼",
                    text: "결혼, 결혼기념일 등 인생에서 찬란하고 <br class='d-none d-xl-block'>눈부신 순간을 기념해요.",
                    image: "/images/guide/temporary/day-marriage.jpg",
                },
                {
                    name: "입학/졸업",
                    text: "새로운 시작과 아쉬운 마지막 순간의 <br class='d-none d-xl-block'>기억을 함께 나누며 기념해요.",
                    image: "/images/guide/temporary/day-school.jpg",
                },
                {
                    name: "임신/출산",
                    text: "새 생명이 찾아온 순간, 세상 밖으로 나온 <br class='d-none d-xl-block'>내 아이의 탄생의 순간을 기념해요.",
                    image: "/images/guide/temporary/day-pregnancy.jpg",
                },
                {
                    name: "취업/승진",
                    text: "성취와 다짐으로 이뤄낸 <br class='d-none d-xl-block'>노력의 결실이 맺어진 날을 기념해요.",
                    image: "/images/guide/temporary/day-job.jpg",
                },
                {
                    name: "나만의 기념임",
                    text: "일상 속 의미있는 날, <br class='d-none d-xl-block'>간직하고 싶은 날 등을 기념해요.",
                    image: "/images/guide/temporary/day-own.jpg",
                },
            ],

            process: [
                {
                    name: "후원사업 문의",
                },
                {
                    name: "후원사업 계획",
                },
                {
                    name: "후원금 전달",
                },
                {
                    name: "후원사업 결과보고",
                },
            ],

            businesses: [
                {
                    image: "/images/guide/temporary/business2-1.jpg",
                    title: "고유목적 복지사업",
                    texts: "가장 시급한 사업 영역에 최우선적으로 지원되며, 위 사업 외에도 후원자의 관심분야와 지역사회의 필요에따라 다양한 사 업을 진행할 수 있습니다.",
                },
                {
                    image: "/images/guide/temporary/business2-2.jpg",
                    title: "국내복지사업",
                    texts: "편견과 차별속에서, 경제적·의료적 어려움으로 고통받는 소외된 이웃들에게 영유아기부터 노년까지 든든한 버팀목이 될 수 있도록 생애주기별 복지서비스를 제공합니다.",
                },
                {
                    image: "/images/guide/temporary/business2-3.jpg",
                    title: "해외복지사업",
                    texts: "빈곤으로 인해 의료, 교육, 영양, 보건 등 기본적인 생활을 보장받지 못하는 개발도상국의 소외된 이웃들을 위해 생명을 구하고, 교육 기회를 제공하며, 건강한 삶을 지원합니다.",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.section {
    position: relative;
}
.day-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .v-card {
        &__title,
        &__text,
        &__image {
            padding-left: 12px;
            padding-right: 12px;
        }
        &__image {
            margin-bottom: 12px;
            .v-image {
                border-radius: 12px;
            }
        }
    }
}
.business-support {
    background-position-x: 80% !important;
}
.process-list {
    > [class*="col"] {
        .process-card {
            padding: 40px;
            height: 100%;
            border-radius: 16px;
            max-width: 400px;
            margin: 0 auto;
        }
        &:not(:last-child) {
            .process-card {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 50%;
                    bottom: -28px;
                    transform: translateX(50%) rotate(90deg);
                    width: 24px;
                    height: 24px;
                    background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                }
            }
        }
    }
}
.business-card {
    border-radius: 16px !important;
    margin: 0 auto;
    height: 100%;
    .v-card {
        &__text {
            word-break: keep-all;
        }
    }
}
@media (min-width: 768px) {
    .day-card {
        height: 100%;
        .v-card {
            &__title,
            &__text,
            &__image {
                padding-left: 24px;
                padding-right: 24px;
            }
            &__title {
                padding-top: 40px;
                padding-bottom: 16px;
            }
            &__text {
                padding-bottom: 32px;
            }
            &__image {
                margin-bottom: 40px;
                .v-image {
                    border-radius: 16px;
                }
            }
        }
    }
    .process-list {
        > [class*="col"] {
            &:not(:last-child) {
                .process-card {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        right: -32px;
                        bottom: 50%;
                        transform: translatey(50%);
                        width: 24px;
                        height: 24px;
                        background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                    }
                }
            }
        }
    }
    .business-card {
        .v-card {
            &__title,
            &__text {
                padding-left: 28px;
                padding-right: 28px;
            }
            &__title {
                padding-top: 40px;
                padding-bottom: 24px;
            }
            &__text {
                padding-bottom: 40px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .information-card {
        height: 100%;
        .v-card {
            &__title,
            &__text,
            &__actions {
                padding-left: 60px;
                padding-right: 60px;
            }
            &__title {
                padding-top: 60px;
            }
            &__actions {
                padding-bottom: 60px;
            }
        }
    }
}
</style>