var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('guide-visual', _vm._b({}, 'guide-visual', _vm.visual, false)), _c('div', {
    staticClass: "client-content-wrap"
  }, [_c('scrolldown-tail'), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v(" 생일·결혼·출산 등 소중한 날에 후원하여, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 더욱 특별하고 의미 있는 하루로 만들어 보세요. ")])]), _c('v-row', {
    staticClass: "row--xxs"
  }, _vm._l(_vm.specialDays, function (day, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "day-card",
      attrs: {
        "flat": "",
        "color": "#FAFAFA"
      }
    }, [_c('div', [_c('v-card-title', {
      staticClass: "tit tit--sm justify-center primary--text"
    }, [_vm._v(" " + _vm._s(day.name) + " ")]), _c('v-card-text', {
      staticClass: "text-center page-text page-text--lg break-keep-all"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(day.text)
      }
    })])], 1), _c('div', {
      staticClass: "v-card__image"
    }, [_c('v-img', {
      attrs: {
        "src": day.image
      }
    })], 1)])], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("지정사업 후원")]), _c('p', {
    staticClass: "section-subtitle"
  }, [_vm._v("지정사업 후원은 귀중한 기부금을 투명하게 전달하고, 후원자가 원하는 분야에 직접적인 도움을 줄 수 있다는 점이 특징입니다.")])])]), _c('v-card', {
    staticClass: "py-60 py-lg-100 business-support",
    attrs: {
      "img": "/images/guide/temporary/business-support.jpg",
      "tile": "",
      "flat": ""
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto mb-24",
    attrs: {
      "max-width": "424",
      "src": "/images/guide/temporary/business-support-text.svg",
      "alt": "세상을 위한 따뜻한 변화, 그 중심에는 나눔의 가치를 실천하는 마음이 있습니다. 따뜻한 나눔을 일시후원으로 시작해 보세요."
    }
  }), _c('v-card', {
    staticClass: "pa-20 pa-lg-40 text-center border-radius-16",
    attrs: {
      "flat": ""
    }
  }, [_c('v-sheet', {
    staticClass: "pa-18 pa-lg-24",
    attrs: {
      "color": "#F2F9EC"
    }
  }, [_c('v-card', {
    staticClass: "font-size-18 px-24 d-inline-flex align-center",
    attrs: {
      "color": "primary",
      "height": "36",
      "outlined": "",
      "rounded": "pill"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("계좌번호 안내")])]), _c('div', {
    staticClass: "tit tit--sm mt-16 primary--text"
  }, [_vm._v(" KB국민은행 사회복지법인 위드캔 복지재단 "), _c('br'), _vm._v(" 017037-04-003957 ")])], 1), _c('p', {
    staticClass: "page-text page-text--lg mt-16"
  }, [_vm._v(" 기부금영수증 국세청 등록을 원하시는 경우, "), _c('br'), _vm._v(" 계좌 입금 후 후원자서비스센터(02-2057-2296)로 연락 주시면 "), _c('br'), _vm._v(" 유선 안내에 따라 필요한 정보를 제공해 주시기 바랍니다. ")]), _c('v-btn', {
    staticClass: "mt-20 mt-md-40",
    attrs: {
      "href": "https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w",
      "target": "_blank",
      "color": "primary",
      "large": "",
      "rounded": "pill"
    }
  }, [_c('span', {
    staticClass: "font-size-18"
  }, [_vm._v("전체사업 일시후원")]), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "border-radius-16"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "information-card",
    attrs: {
      "color": "#EFF5E6",
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "tit tit--md line-height-1 justify-center primary--text font-weight-regular pb-0 text-center"
  }, [_c('h3', [_vm._v("해외 지정사업 후원하기")]), _c('p', {
    staticClass: "page-text page-text--lg grey-1--text mt-18 mt-lg-24 text-center"
  }, [_vm._v(" 해외 식수시설 설치, 학교 건립 및 지원 등의 "), _c('br'), _vm._v(" 다양한 사업을 후원하실 수 있습니다. ")])]), _c('v-card-text', {
    staticClass: "py-20 py-lg-32"
  }, [_c('v-sheet', {
    attrs: {
      "color": "primary",
      "width": "100%",
      "height": "1"
    }
  })], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('ul', [_c('li', [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill d-flex align-center justify-center",
    attrs: {
      "color": "primary",
      "width": "86",
      "height": "30",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "font-size-16 primary--text"
  }, [_vm._v("비용")])])], 1), _c('v-col', [_c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v(" 1,000,000원 이상 "), _c('br'), _vm._v(" (개별상담을 통해 소액후원 진행도 가능합니다.) ")])])], 1)], 1), _c('li', [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill d-flex align-center justify-center",
    attrs: {
      "color": "primary",
      "width": "86",
      "height": "30",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "font-size-16 primary--text"
  }, [_vm._v("지원내용")])])], 1), _c('v-col', [_c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v("우물설치 / 학교 건축 및 교육 / 소득증대 사업 지원 등")])])], 1)], 1)]), _c('div', {
    staticClass: "mt-20 mt-md-32"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "height": "100%"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/guide/temporary/business-1.jpg",
      "alt": ""
    }
  }), _c('v-card-text', {
    staticClass: "text-center page-text page-text--sm pa-12"
  }, [_vm._v("해외 식수 설치")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "height": "100%"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/guide/temporary/business-2.jpg",
      "alt": ""
    }
  }), _c('v-card-text', {
    staticClass: "text-center page-text page-text--sm pa-12"
  }, [_vm._v("학교 건축, 개보수 및 교육 기자재 지원")])], 1)], 1)], 1)], 1)]), _c('v-card-actions', {
    staticClass: "justify-center pt-20 pt-lg-40"
  }, [_c('v-btn', {
    staticClass: "px-24",
    attrs: {
      "color": "primary",
      "large": "",
      "rounded": "pill"
    }
  }, [_c('span', {
    staticClass: "font-size-18"
  }, [_vm._v("해외 지정사업 후원문의")]), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "information-card",
    attrs: {
      "color": "#FFFBE5",
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "tit tit--md line-height-1 justify-center primary--text font-weight-regular pb-0 text-center"
  }, [_c('h3', [_vm._v("국내 지정사업 후원하기")]), _c('p', {
    staticClass: "page-text page-text--lg grey-1--text mt-18 mt-lg-24 text-center"
  }, [_vm._v(" 빈곤가정 및 아이들을 위한 급식, 문화활동 지원 등의 "), _c('br'), _vm._v(" 다양한 사업을 후원하실 수 있습니다. ")])]), _c('v-card-text', {
    staticClass: "py-20 py-lg-32"
  }, [_c('v-sheet', {
    attrs: {
      "color": "primary",
      "width": "100%",
      "height": "1"
    }
  })], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('ul', [_c('li', [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill d-flex align-center justify-center",
    attrs: {
      "color": "primary",
      "width": "86",
      "height": "30",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "font-size-16 primary--text"
  }, [_vm._v("비용")])])], 1), _c('v-col', [_c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v(" 1,000,000원 이상 "), _c('br'), _vm._v(" (개별상담을 통해 소액후원 진행도 가능합니다.) ")])])], 1)], 1), _c('li', [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill d-flex align-center justify-center",
    attrs: {
      "color": "primary",
      "width": "86",
      "height": "30",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "font-size-16 primary--text"
  }, [_vm._v("지원내용")])])], 1), _c('v-col', [_c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v(" 따뜻한 도시락 제공 / 방학 중 소외된 아동 보호 / "), _c('br'), _vm._v(" 방과 후 문화체험 지원 / 지역아동 센터 개보수 등 ")])])], 1)], 1)]), _c('div', {
    staticClass: "mt-20 mt-md-32"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "height": "100%"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/guide/temporary/business-3.jpg",
      "alt": ""
    }
  }), _c('v-card-text', {
    staticClass: "text-center page-text page-text--sm pa-12"
  }, [_vm._v("국내 빈곤가정 지원 및 급식지원")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "height": "100%"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/guide/temporary/business-4.jpg",
      "alt": ""
    }
  }), _c('v-card-text', {
    staticClass: "text-center page-text page-text--sm pa-12"
  }, [_vm._v("학대피해아동 심리치료 지원")])], 1)], 1)], 1)], 1)]), _c('v-card-actions', {
    staticClass: "justify-center pt-20 pt-lg-40"
  }, [_c('v-btn', {
    staticClass: "px-24",
    attrs: {
      "color": "primary",
      "large": "",
      "rounded": "pill"
    }
  }, [_c('span', {
    staticClass: "font-size-18"
  }, [_vm._v("해외 지정사업 후원문의")]), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1)], 1)], 1)])], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("지정사업 후원 진행과정")]), _c('p', {
    staticClass: "section-subtitle"
  }, [_vm._v(" 개인, 단체, 팬클럽, 기업 등 다양한 후원자가 국내외 사업 중 원하는 사업을 상담을 통해 선정하여 진행할 수 있는 맞춤형 후원 방식입니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 지정사업후원을 하시면, 진행된 사업의 현장을 담은 개별화된 결과보고서를 받아보실 수 있습니다. ")])]), _c('v-row', {
    staticClass: "process-list row--lg"
  }, _vm._l(_vm.process, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "process-card",
      attrs: {
        "color": "#FAFAFA",
        "flat": ""
      }
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "primary--text text-center font-weight-bold font-size-28"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('div', {
      staticClass: "page-text page-text--lg grey-1--text text-center mt-8"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('v-img', {
      staticClass: "mt-24 mx-auto",
      attrs: {
        "src": `/images/guide/temporary/progress-${index + 1}.svg`,
        "max-width": "60"
      }
    })], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("일시후원금은 어떻게 사용될까요?")])]), _c('v-row', _vm._l(_vm.businesses, function (business, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "business-card",
      attrs: {
        "max-width": "384",
        "flat": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": business.image
      }
    }), _c('v-card-title', {
      staticClass: "tit tit--sm justify-center primary--text"
    }, [_vm._v(" " + _vm._s(business.title) + " ")]), _c('v-card-text', {
      staticClass: "text-center page-text page-text--lg break-keep-all"
    }, [_vm._v(" " + _vm._s(business.texts) + " ")])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }